import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useTranslation } from 'next-i18next';
import Pusher from 'pusher-js';
import toast from 'react-hot-toast';
import { AttendeeMessage, MeetingStatusUpdate, NotificationInterface, PhotoBooth, UserBan } from '../interfaces/interfaces';
import { setCheckActivities, setUserCount } from '../reduxToolkit/slices/adminSlice';
import {
  fetchAndSetMessagingContactshares,
  setAttendeeMessaging,
  setAttendeeNewMessage,
  setBroadcast,
  setCallingState,
  setCallState,
  setConnectCount,
  setFadableElement,
  setLiveStreams,
  setMeetingroomStatus,
  setNotifications,
  setPageData,
  setPages,
  setPhotoBooths,
  setPusher,
  setPusherEventChannel,
  setPusherMatchingChannel,
  setPusherMeetingChannel,
  setPusherPresenceChannel,
  setPusherReactionChannel,
  setPusherUserEventChannel,
  setWantsInvite,
  userJoinedMeetingRoom,
  userLeftMeetingRoom,
} from '../reduxToolkit/slices/commonSlice';
import { useSelector, useDispatch } from '../reduxToolkit/typedRedux';
import { getPage } from '../services/services';
import { getToken } from '../utils/auth';
import { CALLINGSTATE } from '../utils/enums';
import { useMeeting } from './useMeeting';
import useMessaging from './useMessaging';
import { useSounds } from './useSounds';
import { setIsBannedFromChat } from '../reduxToolkit/slices/chatSlice';
import { store } from '../reduxToolkit/store';

/**
 * This hook is utilized by the global.tsx component to connect
 * to pusher and to setup the appropriate event handlers
 */
export const usePusher = () => {
  const pusher = useSelector((state) => state.common.pusher);
  const pusherChannels = useSelector((state) => state.common.pusherChannels);
  const callState = useSelector(({ common }) => common.callState);
  const registration = useSelector(({ common }) => common.eventRegistration);

  const dispatch = useDispatch();
  const { playPhoneRing } = useSounds();

  const { t } = useTranslation();
  const { onNewConversation } = useMessaging();
  const { convertMeetingUpdate } = useMeeting();
  async function initPusher() {
    console.log('vystem Realtime > Initalizing...');
    const token = getToken();

    if (pusher) {
      console.log('vystem Realtime > Pusher already exists. Disconnecting..');
      pusher.unbind_all();
      pusher.disconnect();
    }

    //The env variable is automatically replaced by the build pipeline
    // be careful changing anything in the following block. This can effect the build process!!!
    const pusherInstance = new Pusher(process.env['stagingPusher']!, {
      cluster: 'eu',
      authEndpoint: `${process?.env?.baseURL}${process.env.basePath}auth/pusherauth`,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });

    pusherInstance.connection.bind('error', function (err: any) {
      //Try catch to be extra safe about the error object
      try {
        console.log(`vystem Realtime > Connection error ${JSON.stringify(err?.error)}`);
      } catch (err) {
        console.error(`vystem Realtime > Error > ${err}`);
      }
    });

    pusherInstance.connection.bind('connecting', function () {
      console.log(`vystem Realtime > Connecting...`);
    });

    pusherInstance.connection.bind('connected', function () {
      console.log(`vystem Realtime > Connected`);
    });

    pusherInstance.connection.bind('disconnected', function () {
      console.log(`vystem Realtime > Disconnected`);
    });

    pusherInstance.connection.bind('failed', function () {
      console.log(`vystem Realtime > Failed`);
    });

    dispatch(setPusher(pusherInstance));
  }

  async function setupUserEventChannel(eventId?: string, userId?: string, registrationId?: string) {
    if (!userId || !eventId || !pusher) return;

    if (pusherChannels?.userEventChannel?.name) {
      pusher.unsubscribe(pusherChannels.userEventChannel.name);
      console.log('vystem Realtime > Unsubscribing from usereventchannel');
    }

    const userEventChannel = pusher.subscribe(`user-${eventId}-${userId}${registrationId ? `-${registrationId}` : ''}`);

    userEventChannel.bind('matching-update', (data: { isActive: boolean }) => {
      dispatch(setWantsInvite(data.isActive));
    });
    userEventChannel.bind('meeting-join-kick', (data: { newMeetingId: string }) => {
      if (store.getState().video.meeting?._id === data.newMeetingId) {
        toast(t('meeting:toasts.removed-from-other-meeting'));
      }
    });

    dispatch(setPusherUserEventChannel(userEventChannel));
    userEventChannel.bind(`attendee-messaging`, (data: { message: AttendeeMessage }) => {
      console.log('vystem Realtime > Attendee message incoming ', data.message);
      const state = store.getState();
      onNewConversation(data.message);
      if (data.message.messageType === 'VCARD' && state.common.event) {
        dispatch(fetchAndSetMessagingContactshares(state.common.event._id));
      }
      try {
        dispatch(setAttendeeNewMessage(data.message));
      } catch (err) {
        console.error(`Socketerror > ${err}`);
      }
    });

    userEventChannel.bind(`notification-receive`, (data: NotificationInterface) => {
      console.log('vystem Realtime > Notification received ', data);
      const state = store.getState();
      dispatch(setNotifications([data, ...state.common.notifications]));
    });

    userEventChannel.bind(`messaging-report`, (data: { reporter: string }) => {
      console.log('Vystem Realtime > Notification received ', data);
      const messaging = store.getState().common.attendeeMessaging;
      if (messaging?.reporters?.includes(data.reporter)) return;
      const _reporters = messaging?.reporters ?? [];
      dispatch(setAttendeeMessaging({ ...messaging, reporters: [..._reporters, data.reporter] }));
    });

    userEventChannel.bind(`call-incoming`, (data: { meetingId: string; name: string; calleeId: string }) => {
      console.log('Vystem Realtime > Call incoming received', data);

      if (callState.meetingId || callState.calleeId) {
        console.log('Erroneous call-incoming event while call is running.');
        return;
      }

      dispatch(
        setCallState({
          current: CALLINGSTATE.BEINGCALLED,
          showModal: true,
          meetingId: data.meetingId,
          callerName: data.name,
          participants: [],
          camActive: true,
          micActive: true,
          calleeId: data.calleeId,
        })
      );
    });

    userEventChannel?.bind('call-canceled', (data: {}) => {
      console.log('Vystem Realtime > Call canceled', data);
      dispatch(setCallingState(CALLINGSTATE.CANCELED));
      toast(t('meeting:on-site-call.canceled'));
    });

    userEventChannel?.bind('call-handled', (data: {}) => {
      console.log('Vystem Realtime > Call handled');
      if (store.getState().common.callState.current === CALLINGSTATE.CONNECTED || store.getState().common.callState.current === CALLINGSTATE.CALLING) {
        console.log('Vystem Realtime > Tab is active, hence not closing modal in this one.');
        return;
      }
      dispatch(setCallingState(CALLINGSTATE.HANDLED));
    });

    userEventChannel.bind(`ban-chat`, ({ userban }: { userban: UserBan }) => {
      console.log('Vystem Realtime > Chat ban');
      dispatch(setIsBannedFromChat(!userban?.datetime_deleted));
    });
  }

  async function setupMatchingChannel(registrationId: string) {
    const localStore = store.getState();
    const pusherFromStore = localStore.common.pusher;

    if (!registrationId || !pusherFromStore) return;

    if (pusherChannels?.matchingChannel?.name) {
      pusherFromStore.unsubscribe(pusherChannels.matchingChannel.name);
      console.log('vystem Realtime > Unsubscribing from matchingchannel');
    }

    console.log(`vystem Realtime: Subscribing to matching-${registrationId}`);
    const matchingChannel = pusherFromStore.subscribe(`matching-${registrationId}`);

    dispatch(setPusherMatchingChannel(matchingChannel));
  }

  async function clearMatchingChannel() {
    if (!pusher) return;

    if (pusherChannels?.matchingChannel?.name) {
      pusher.unsubscribe(pusherChannels.matchingChannel.name);
      console.log('vystem Realtime > Unsubscribing from matchingchannel');
    }

    dispatch(setPusherMatchingChannel(null));
  }

  async function setupReactionChannel(eventId?: string, pageId?: string) {
    if (!pageId || !pageId || !pusher) return;

    console.log('vystem Realtime > Subscribed to reaction channel');

    if (pusherChannels?.reactionChannel?.name) {
      pusher.unsubscribe(pusherChannels.reactionChannel.name);
      console.log('vystem Realtime > Unsubscribing from reactioneventchannel');
    }

    // eventId is not set when this channel is setup from a meeting, because the meeting type 'temporary' does not have an event at all
    let reactionEventChannel;
    if (eventId) reactionEventChannel = pusher.subscribe(`reaction-${eventId}-${pageId}`);
    else reactionEventChannel = pusher.subscribe(`reaction-${pageId}`);

    dispatch(setPusherReactionChannel(reactionEventChannel));
  }

  async function handleEventChange(eventId?: string) {
    if (eventId) console.log('Handle event change to ', eventId);
    if (!pusher) return;

    if (pusherChannels?.eventChannel?.name) {
      pusher.unsubscribe(pusherChannels.eventChannel.name);
      console.log('vystem Realtime > Unsubscribing from eventchannel');
    }

    if (eventId) {
      console.log('vystem Realtime > Subscribing to eventchannel ', eventId);
      const eventChannel = pusher.subscribe(`event-${eventId}`);

      dispatch(setPusherEventChannel(eventChannel));

      eventChannel.bind('checkInOut', ({ data }: { data: any }) => {
        try {
          const state = store.getState();
          const newData = [data, ...(state.admin.checkActivities && state.admin.checkActivities?.length > 0 ? state.admin.checkActivities : [])];
          store.dispatch(setCheckActivities([...newData.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()]));
        } catch (err) {
          console.error(err);
        }
      });

      eventChannel.bind('pageupdate', async ({ pageid }: { pageid: string }) => {
        const state = store.getState();

        if (state.common.templateEditMode === false) {
          const { data: pagedata } = await getPage(pageid);
          store.dispatch(setPages(state.common.pages.map((p) => (p._id === pagedata._id ? pagedata : p))));
          if (state.common.pagedata?._id === pageid) {
            store.dispatch(setPageData({ ...pagedata }));
          }
        }
      });

      eventChannel.bind('uiComponentDelete', (data: any) => {
        console.log('vystem Realtime > Ui Component delete incoming ', data);
        const state = store.getState();
        if (state.common.templateEditMode === false) {
          const _page = state.common.pages.find((p) => p._id === data.pageId);
          if (!_page) return;
          const _components = [..._page.uicomponents];
          _components.splice(_components.map((c) => c._id).indexOf(data.componentId), 1);
          store.dispatch(setPages(state.common.pages.map((p) => (p._id === data.pageId ? { ...p, uicomponents: _components } : p))));
          if (state.common.pagedata && state.common.pagedata._id === data.pageId)
            store.dispatch(setPageData({ ...state.common.pagedata, uicomponents: _components }));
        }
      });

      eventChannel.bind('uiComponentToggle', (data: any) => {
        console.log('Vystem Realtime > Ui Component toggle incoming ', data);
        const state = store.getState();
        if (state.common.templateEditMode === false && state.common.pagedata) {
          store.dispatch(
            setPageData({
              ...state.common.pagedata,
              uicomponents: state.common.pagedata.uicomponents.map((component) =>
                component._id === data.componentId ? { ...component, isVisible: data.isVisible } : component
              ),
            })
          );
        }
      });

      eventChannel.bind('fadable', (data: any) => {
        console.log('vystem Realtime > Ui fadable coming in  ', data);
        const state = store.getState();
        if (data.event === state.common.event?._id) {
          store.dispatch(
            setFadableElement({
              name: data.name,
              visible: data.visible,
            })
          );
        }
      });

      eventChannel.bind('usercount', (data: { count: number }) => {
        store.dispatch(setConnectCount(data.count));
        store.dispatch(setUserCount(data.count));
      });

      eventChannel.bind('stream-update', (data: { streamId: string; isBroadcasting?: boolean; isEncoderConnected?: boolean }) => {
        console.log('Stream update incoming ', data);
        const state = store.getState();
        store.dispatch(
          setLiveStreams(
            state.common.livestreams.map((stream) => {
              if (stream._id === data.streamId) {
                return {
                  ...stream,
                  ...data,
                };
              }
              return stream;
            })
          )
        );
      });

      eventChannel.bind('meetingjoin', (data: { meetingId: string; user: { name: string; _id: string; avatar: string; organization: string } }) => {
        // console.log('Meetingjoin >', data);
        try {
          store.dispatch(userJoinedMeetingRoom({ meetingId: data.meetingId, user: data.user }));
        } catch (err) {
          console.error(`Socketerror > ${err}`);
        }
      });
      eventChannel.bind('meetingleave', (data: { meetingId: string; userId: string }) => {
        // console.log('Meetingleave > ', data);
        try {
          store.dispatch(userLeftMeetingRoom({ meetingId: data.meetingId, userId: data.userId }));
        } catch (err) {
          console.error(`Socketerror > ${err}`);
        }
      });

      eventChannel.bind('meetingstatusupdate', (data: MeetingStatusUpdate[]) => {
        data.forEach((data) => {
          try {
            const convertedData = convertMeetingUpdate(data);
            store.dispatch(setMeetingroomStatus(convertedData));
          } catch (err) {
            console.error(`Meetingstatus Update > ${err}`);
          }
        });
      });

      eventChannel.bind('broadcast', (data: { message: string; type: string; _id: string }) => {
        console.log('Eventbroadcast > ', data);
        try {
          dispatch(setBroadcast(data));
        } catch (err) {
          console.error(`Pushererror > ${err}`);
        }
      });

      eventChannel.bind('broadcaststop', (data: { broadcastId: string }) => {
        console.log('Eventbroadcast > ', data);
        try {
          const state = store.getState();
          if (state.common.broadcast._id === data.broadcastId) dispatch(setBroadcast(undefined));
        } catch (err) {
          console.error(`Pushererror > ${err}`);
        }
      });
      eventChannel.bind('photobooth-create', (photobooth: PhotoBooth) => {
        try {
          const state = store.getState();
          const photobooths = [...state.common.photobooths];
          if (!photobooths.find((pb) => pb.user._id === photobooth.user._id)) {
            photobooths.push(photobooth);
            dispatch(setPhotoBooths(photobooths));
          }
        } catch (err) {
          console.error(`vystem Realtime > Photobooth created`);
        }
      });
      eventChannel.bind('photobooth-delete', (data: { photoboothid: string }) => {
        try {
          const state = store.getState();
          const photobooths = [...state.common.photobooths].filter((pb) => pb._id !== data.photoboothid);
          dispatch(setPhotoBooths(photobooths));
        } catch (err) {
          console.error(`vystem Realtime > Photobooth deleted`);
        }
      });
      eventChannel.bind('photobooth-update', (photobooth: PhotoBooth) => {
        try {
          const state = store.getState();
          const photobooths = [...state.common.photobooths].map((pb) => {
            if (photobooth._id === pb._id) return photobooth;
            return pb;
          });
          dispatch(setPhotoBooths(photobooths));
        } catch (err) {
          console.error(`vystem Realtime > Photobooth updated`);
        }
      });
    } else {
      dispatch(setPusherEventChannel(null));
      dispatch(setPusherUserEventChannel(null));
    }
  }

  async function setupMeetingChannel(meetingId?: string) {
    if (!meetingId || !pusher) return;

    if (pusherChannels?.meetingChannel?.name) {
      pusher.unsubscribe(pusherChannels.meetingChannel.name);
      console.log('vystem Realtime > Unsubscribing from meetingchannel');
    }

    const meetingChannel = pusher.subscribe(`meeting-${meetingId}`);
    dispatch(setPusherMeetingChannel(meetingChannel));
  }

  async function setupPresenceChannel(eventId?: string, pageId?: string, userId?: string, allowPresenceTracking = true) {
    if (!eventId || !pusher) return;

    if (pusherChannels?.presenceChannel?.name) {
      pusher.unsubscribe(pusherChannels.presenceChannel.name);
      console.log('vystem Realtime > Unsubscribing from presencechannel');
    }
    if (!allowPresenceTracking) return;

    /**
     * Generate a unique key for the user's browser
     */
    const fpPromise = await FingerprintJS.load();
    const identifierResult = await fpPromise.get();
    const deviceKey = identifierResult.visitorId || 'Failed';
    const identifier = `key=${deviceKey}${userId ? `-user=${userId}` : ''}${registration ? `-registration=${registration._id}` : ''}`;
    const page = `${pageId ? `-page=${pageId}` : ''}`;
    const registrationId = registration ? `-registration=${registration._id}` : '';
    const channel = `ep-${eventId}-${identifier}${page}${registrationId}`;

    const presenceChannel = pusher.subscribe(channel);
    // console.log('Subscribing to presence channel ', presenceChannel);
    dispatch(setPusherPresenceChannel(presenceChannel));
  }

  async function setupBoothChannel(eventId?: string) {
    if (!eventId || !pusher) return;

    /*if (pusherChannels?.boothChannel?.name) {
      pusher.unsubscribe(pusherChannels.boothChannel.name);
      console.log('vystem Realtime > Unsubscribing from booth channel');
    }

    const boothChannel = pusher.subscribe(`booth-${eventId}`);
    dispatch(setPusherBoothChannel(boothChannel));*/
  }

  return {
    initPusher,
    handleEventChange,
    setupUserEventChannel,
    setupMeetingChannel,
    setupPresenceChannel,
    setupBoothChannel,
    setupReactionChannel,
    setupMatchingChannel,
    clearMatchingChannel,
  };
};
